<template>
  <div id="page-container">
    <div id="content-wrap">
      <b-row class="m-0 p-0 justify-content-center" style="margin-top: 6% !important">
        <b-col md="3" sm="3" lg="3" class="mb-2">
          <b-col>
            <img class="mb-2 img-fluid imagens" src="@/assets/bh1.jpeg"/>
          </b-col>
          <b-col>
            <b-button variant="danger" @click="registro('bh')">
              Checagem
            </b-button>
          </b-col>
        </b-col>
        <b-col md="3" sm="3" lg="3" class="mb-2">
          <b-col>
            <img class="mb-2 img-fluid imagens" src="@/assets/bh2.jpeg"/>
          </b-col>
          <b-col>
            <b-button variant="danger" @click="registro('bh')">
              Checagem
            </b-button>
          </b-col>
        </b-col>
        <b-col md="3" sm="3" lg="3" class="mb-2">
          <b-col>
            <img class="mb-2 img-fluid imagens" src="@/assets/bh3.jpeg"/>
          </b-col>
          <b-col>
            <b-button variant="danger" @click="registro('bh')">
              Checagem
            </b-button>
          </b-col>
        </b-col>
        <b-col md="3" sm="3" lg="3" class="mb-2">
          <b-col>
            <img class="mb-2 img-fluid imagens" src="@/assets/bh4.jpeg"/>
          </b-col>
          <b-col>
            <b-button variant="danger" @click="registro('bh')">
              Checagem
            </b-button>
          </b-col>
        </b-col>
        <b-col md="12" sm="12" lg="5" class="mb-2" v-if="false">
          <b-col>
            <img class="mb-2 img-fluid imagens" src="@/assets/ls.png"/>
          </b-col>
          <b-col>
            <b-button variant="primary" @click="registro('ls')">
              Checagem
            </b-button>
          </b-col>
        </b-col>
      </b-row>
    </div>
    <footer id="footer">
      <p class="mt-2">
        Copyright © 2022 Agência Liga - Todos os direitos reservados
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    registro(evento) {
      if (evento === 'bh') {
        window.open("https://genesisesportes.com.br/bhjjopen/", '_blank')
      } else {
        window.open("https://genesisesportes.com.br/bhjjopen/", '_blank')
      }
    }
  }
}
</script>
<style>
#page-container {
  position: relative;
  min-height: 93.5vh;
  overflow: hidden;
}

#content-wrap {
  padding-bottom: 2.5rem; /* Footer height */
  overflow: hidden;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
  overflow: hidden;
  background-color: grey;
  color: white;
}

@media only screen and (max-width: 1920px) {
  .imagens {
    width: 100% !important;
  }
}


/* mobile view.
 * Largest mobile view is iPad Pro with 1366 in landscape mode
 */
@media only screen and (max-width:1365px) {
  body::after {
    content: "";
    background: url(../assets/mobile.png);
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    filter: grayscale(100%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100vw 100vh;

  }
}

/* Desktop-only view.
 * Largest mobile view is iPad Pro with 1366 in landscape mode
 */
@media only screen and (min-width:1366px) {
  body::after {
    content: "";
    background: url(../assets/fundo-full.png);
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    filter: grayscale(100%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100vw 100vh;

  }
}
</style>
